import React from "react";
import Heading from "./Heading";
import { Link } from "react-router-dom";
import { CityData } from "../Data/CityData";
import { motion } from "framer-motion";

const City = () => {
  return (
    <div className="py-12 lg:py-24 bg-troo-tertiary">
      <div className="main_container1">
        <div className="flex flex-col md:flex-row gap-5 justify-between mb-12">
          <div>
            <Heading title="Choose Your City" heading="Property by cities" />
          </div>
          <div className="flex justify-end items-end underline">
            <Link className="font-Nunito font-semibold">View All Cities</Link>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {CityData.map((e, i) => (
            <motion.div
            initial={{ y: 30, opacity: 0, scale: 0.5 }}
            whileInView={{ y: 0, opacity: 1, scale: 1 }}
            transition={{ type: "spring", stiffness: "100", delay: 0.1 * i }}
            >
              <Link
                className="p-2 group bg-troo-white flex items-center gap-5 hover:bg-troo-secondary hover:text-troo-white transition-all ease-in-out duration-500"
                key={i}
              >
                <div>
                  <img className="group-hover:border-[2px] border-[2px] transition-all ease-in-out duration-500 group-hover:border-troo-primary " src={e.img} alt={e.name} />
                </div>
                <div className="flex flex-col gap-2">
                  <h4>{e.name}</h4>
                  <p>{e.para}</p>
                </div>
              </Link>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default City;
