import React from "react";
import Heading from "./Heading";
import contacImg from "../images/rightContact.png";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Contact = () => {
  return (
    <div className="bg-contact-bg bg-no-repeat w-full h-full py-12 lg:py-24">
      <div className="main_container1">
        <motion.div
          initial={{ y: -50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 2, type: "spring" }}
          className="mb-12"
        >
          <Heading
            title="Need any Help?"
            heading={
              <>
                We are ready to help you! Request <br />
                for free consultation.
              </>
            }
            isCenter
          />
        </motion.div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div className="shadow-contact p-8">
            <motion.form
              initial={{ x: -50, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 2, type: "spring" }}
              className="mt-6 w-full"
            >
              <div className="flex flex-col gap-y-8">
                <div className="grid w-full grid-cols-1 md:grid-cols-2 gap-4 md:gap-7">
                  <div className="w-full relative">
                    <p className="mb-3">Full Name</p>
                    <input
                      type="text"
                      placeholder="Full Name"
                      className="p-4 bg-transparent border border-troo-black1 rounded w-full placeholder:text-troo-secondary"
                    />
                  </div>
                  <div className="relative w-full">
                    <p className="mb-3">Your Email</p>
                    <input
                      type="email"
                      placeholder="Your Email Address"
                      className="p-4  bg-transparent border border-troo-black1 rounded w-full placeholder:text-troo-secondary"
                    />
                  </div>
                  <div className="relative w-full">
                    <p className="mb-3">Enter Your Phone</p>
                    <input
                      type="number"
                      placeholder="Enter Your Phone"
                      className="p-4  bg-transparent border border-troo-black1 rounded w-full placeholder:text-troo-secondary"
                    />
                  </div>
                  <div className="relative w-full">
                    <p className="mb-3">Inquiry Type</p>
                    <select className="p-4  bg-transparent border border-troo-black1 rounded w-full placeholder:text-troo-secondary">
                      <option disabled selected>
                        Inquiry Type
                      </option>
                      <option>500 Sq</option>
                      <option>1000 Sq</option>
                      <option>1500 Sq</option>
                    </select>
                  </div>
                  <div className="relative w-full">
                    <p className="mb-3">Max Price</p>
                    <input
                      type="email"
                      placeholder="$20.000"
                      className="p-4  bg-transparent border border-troo-black1 rounded w-full placeholder:text-troo-secondary"
                    />
                  </div>
                  <div className="relative w-full">
                    <p className="mb-3">Min Size (Sq ft)</p>
                    <input
                      type="number"
                      placeholder="500 Sq"
                      className="p-4  bg-transparent border border-troo-black1 rounded w-full placeholder:text-troo-secondary"
                    />
                  </div>
                </div>

                <div>
                  <Link className="btn1 bg-troo-primary text-troo-white hover:bg-troo-secondary">
                    Submit Inquiry
                  </Link>
                </div>
              </div>
            </motion.form>
          </div>
          <div>
            <motion.img
              initial={{ x: 50, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 2, type: "spring" }}
              className="w-full h-full"
              src={contacImg}
              alt="contactImg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
