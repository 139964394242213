import React from "react";
import { GoCheckCircle } from "react-icons/go";
import propertyImg from "../images/propertyImg1.png";
import { HiOutlineChevronRight, HiOutlineMailOpen } from "react-icons/hi";
import { BiPhoneCall } from "react-icons/bi";
import { HiOutlineShare } from "react-icons/hi2";
import { Link } from "react-router-dom";
import { TeamData } from "../Data/TeamData";
import { motion } from "framer-motion";
import { FiMail, FiUser } from "react-icons/fi";

const PropertyDetails = () => {
  return (
    <div className="py-12 bg-troo-tertiary">
      <div className="main_container1">
        <div className="flex flex-col lg:flex-row h-full items-start gap-6">
          <div className="w-full lg:w-[70%]">
            <div className="p-8 bg-troo-white flex flex-col gap-5">
              <h3 className="pb-5 relative before:content-[''] before:absolute before:bottom-0 before:w-16 before:h-[3px] before:bg-troo-black">
                Property Details
              </h3>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable. If you are going to use a passage of Lorem
                Ipsum, you need to be sure there isn't anything embarrassing
                hidden in the middle of text. All the Lorem Ipsum generators on
                the Internet tend to repeat predefined chunks as necessary,
                making this the first true generator on the Internet. It uses a
                dictionary of over 200 Latin words, combined with a handful of
                model sentence structures, to generate Lorem Ipsum which looks
                reasonable. The generated Lorem Ipsum is therefore always free
                from repetition, injected humour, or non-characteristic words
                etc.
              </p>
              <h4>Additional Details</h4>
              <div className="grid grid-cols-1 md:grid-cols-2 xl:flex justify-between gap-5 w-full ">
                <diiv className="flex flex-col gap-5">
                  <p className="flex items-center gap-2">
                    <GoCheckCircle className="text-troo-secondary text-[20px]" />
                    Building Age:
                    <span className="font-semibold"> 2 Years </span>
                  </p>
                  <p className="flex items-center gap-2">
                    <GoCheckCircle className="text-troo-secondary text-[20px]" />
                    Exercise Room:
                    <span className="font-semibold">Yes </span>
                  </p>
                  <p className="flex items-center gap-2">
                    <GoCheckCircle className="text-troo-secondary text-[20px]" />
                    Heating:
                    <span className="font-semibold"> Yes </span>
                  </p>
                </diiv>
                <div className="flex flex-col gap-5">
                  <p className="flex items-center gap-2">
                    <GoCheckCircle className="text-troo-secondary text-[20px]" />
                    Cooling:
                    <span className="font-semibold"> Yes </span>
                  </p>
                  <p className="flex items-center gap-2">
                    <GoCheckCircle className="text-troo-secondary text-[20px]" />
                    Parking:
                    <span className="font-semibold"> Yes </span>
                  </p>
                  <p className="flex items-center gap-2">
                    <GoCheckCircle className="text-troo-secondary text-[20px]" />
                    Water:
                    <span className="font-semibold"> Yes </span>
                  </p>
                </div>
                <div className="flex flex-col gap-5">
                  <p className="flex items-center gap-2">
                    <GoCheckCircle className="text-troo-secondary text-[20px]" />
                    Gas line:
                    <span className="font-semibold"> Yes </span>
                  </p>
                  <p className="flex items-center gap-2">
                    <GoCheckCircle className="text-troo-secondary text-[20px]" />
                    Shower:
                    <span className="font-semibold"> Yes </span>
                  </p>
                  <p className="flex items-center gap-2">
                    <GoCheckCircle className="text-troo-secondary text-[20px]" />
                    Storage:
                    <span className="font-semibold"> Yes </span>
                  </p>
                </div>
              </div>
              <h4>Nearest Place</h4>
              <div className="grid grid-cols-1 md:grid-cols-2 xl:flex justify-between gap-5 w-full ">
                <diiv className="flex flex-col gap-5">
                  <p className="flex items-center gap-2">
                    <GoCheckCircle className="text-troo-secondary text-[20px]" />
                    Airport:
                    <span className="font-semibold"> 3 km </span>
                  </p>
                  <p className="flex items-center gap-2">
                    <GoCheckCircle className="text-troo-secondary text-[20px]" />
                    Beach
                    <span className="font-semibold">3.5 km </span>
                  </p>
                  <p className="flex items-center gap-2">
                    <GoCheckCircle className="text-troo-secondary text-[20px]" />
                    Train Station
                    <span className="font-semibold"> 2 km </span>
                  </p>
                </diiv>
                <div className="flex flex-col gap-5">
                  <p className="flex items-center gap-2">
                    <GoCheckCircle className="text-troo-secondary text-[20px]" />
                    School:
                    <span className="font-semibold"> 1 km </span>
                  </p>
                  <p className="flex items-center gap-2">
                    <GoCheckCircle className="text-troo-secondary text-[20px]" />
                    Park
                    <span className="font-semibold"> 300 mtr </span>
                  </p>
                  <p className="flex items-center gap-2">
                    <GoCheckCircle className="text-troo-secondary text-[20px]" />
                    Gym:
                    <span className="font-semibold"> In Room </span>
                  </p>
                </div>
                <div className="flex flex-col gap-5">
                  <p className="flex items-center gap-2">
                    <GoCheckCircle className="text-troo-secondary text-[20px]" />
                    Hospital:
                    <span className="font-semibold"> 1.5 km </span>
                  </p>
                  <p className="flex items-center gap-2">
                    <GoCheckCircle className="text-troo-secondary text-[20px]" />
                    Collage:
                    <span className="font-semibold"> 5 km </span>
                  </p>
                </div>
              </div>
              <h4>Aminities</h4>
              <div className="grid grid-cols-1 md:grid-cols-2 xl:flex justify-between gap-5 w-full ">
                <diiv className="flex flex-col gap-5">
                  <p className="flex items-center gap-2">
                    <GoCheckCircle className="text-troo-secondary text-[20px]" />
                    Elevator in building
                  </p>
                  <p className="flex items-center gap-2">
                    <GoCheckCircle className="text-troo-secondary text-[20px]" />
                    CCTC Camera
                    <span className="font-semibold">Yes </span>
                  </p>
                  <p className="flex items-center gap-2">
                    <GoCheckCircle className="text-troo-secondary text-[20px]" />
                    Balcony
                  </p>
                </diiv>
                <div className="flex flex-col gap-5">
                  <p className="flex items-center gap-2">
                    <GoCheckCircle className="text-troo-secondary text-[20px]" />
                    Coffe and tea
                  </p>
                  <p className="flex items-center gap-2">
                    <GoCheckCircle className="text-troo-secondary text-[20px]" />
                    Balcony
                  </p>
                  <p className="flex items-center gap-2">
                    <GoCheckCircle className="text-troo-secondary text-[20px]" />
                    Bathtub
                  </p>
                </div>
                <div className="flex flex-col gap-5">
                  <p className="flex items-center gap-2">
                    <GoCheckCircle className="text-troo-secondary text-[20px]" />
                    Cable Tv
                  </p>
                  <p className="flex items-center gap-2">
                    <GoCheckCircle className="text-troo-secondary text-[20px]" />
                    Air Condition
                  </p>
                </div>
              </div>
            </div>
            <div className="p-8 bg-troo-white flex flex-col gap-8">
              <h3 className="pb-5 relative before:content-[''] before:absolute before:bottom-0 before:w-16 before:h-[3px] before:bg-troo-black">
                Floor PPlans
              </h3>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable.
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 items-center">
                <div className="w-full">
                  <img src={propertyImg} alt="propertyimg" />
                </div>
                <div className="flex flex-col gap-8">
                  <h4>1st Floor Stature</h4>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration in some
                    form, by injected humour, or randomised words which don't
                    look even slightly believable. If you are going to use a
                    passage of Lorem Ipsum, you need to be sure there isn't
                    anything embarrassing hidden in the middle of text.
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 items-center mt-8">
                <div className="flex flex-col gap-8">
                  <h4>2nd Floor Stature</h4>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration in some
                    form, by injected humour, or randomised words which don't
                    look even slightly believable. If you are going to use a
                    passage of Lorem Ipsum, you need to be sure there isn't
                    anything embarrassing hidden in the middle of text.
                  </p>
                </div>
                <div className="w-full">
                  <img src={propertyImg} alt="propertyimg" />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:sticky top-40 lg:w-[30%] flex flex-col items-start gap-4">
            <h4>Property Agent</h4>
            <div className="bg-troo-white p-5">
              {TeamData.slice(1, 2).map((e, i) => (
                <div key={i}>
                  <Link to={e.path} className="flex flex-col gap-6">
                    <div className="relative">
                      <img src={e.img} alt={e.name} />
                      <div className="group">
                        <motion.div
                          initial={{ y: 4, opacity: 0 }}
                          whileInView={{ y: 0, opacity: 1 }}
                          transition={{ type: "spring", stiffness: "100" }}
                          className="absolute right-5 bg-troo-secondary bottom-5 p-1  rounded-full hidden group-hover:flex  flex-col gap-2"
                        >
                          {e.social.map((ele, j) => (
                            <motion.span
                              initial={{ y: 4, opacity: 0 }}
                              whileInView={{ y: 0, opacity: 1 }}
                              transition={{
                                type: "spring",
                                stiffness: "100",
                                delay: 0.2 * j,
                              }}
                            >
                              <Link
                                to={ele.path}
                                key={j}
                                className="flex justify-center items-center w-7 h-7 p-1 bg-troo-white hover:bg-troo-primary transition-all duration-300 ease-in-out rounded-full"
                              >
                                {ele.icon}
                              </Link>
                            </motion.span>
                          ))}
                        </motion.div>
                        <div className="absolute right-5 bg-troo-secondary bottom-5 p-1  rounded-full flex group-hover:hidden flex-col gap-2">
                          <Link className="flex justify-center items-center w-7 h-7 p-1 bg-troo-white hover-bg-troo-primary transition-all duration-300 ease-in-out rounded-full">
                            <HiOutlineShare />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between items-center gap-5">
                      <div className="flex flex-col gap-3">
                        <h4>{e.name}</h4>
                        <p>{e.sub}</p>
                      </div>
                      <div className="flex gap-5">
                        <div className="p-1 w-8 h-8 bg-troo-secondary hover:bg-troo-primary transition-all duration-500 ease-in-out flex justify-center items-center text-troo-white rounded-full">
                          <BiPhoneCall />
                        </div>
                        <div className="p-1 w-8 h-8 bg-troo-secondary hover:bg-troo-primary transition-all duration-500 ease-in-out flex justify-center items-center text-troo-white rounded-full">
                          <HiOutlineMailOpen />
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
            <div className="bg-troo-white p-5 w-full">
              <h4 className="mb-3">Schedule a Meeting</h4>
              <p>Choose your preferred day</p>
              <div className="flex flex-col  gap-5 pt-6">
                  <div className="relative w-full ">
                    <p className="mb-3">Full Name</p>
                    <FiUser className="absolute top-[70%] left-3 transform -translate-y-1/2" />
                    <input
                      type="text"
                      placeholder="Full Name"
                      className="p-4 pl-10 bg-transparent border border-troo-hrtag rounded w-full placeholder:text-troo-secondary"
                    />
                  </div>
                  <div className="relative w-full">
                    <p className="mb-3">Your Email</p>
                    <FiMail className="absolute top-[70%] left-3 transform -translate-y-1/2" />
                    <input
                      type="email"
                      placeholder="Your Email Address"
                      className="p-4 pl-10 bg-transparent border border-troo-hrtag rounded w-full placeholder:text-troo-secondary"
                    />
                  </div>
                  <div className="relative w-full">
                    <p className="mb-3">Date</p>
                    <input
                      type="date"
                     
                      className="p-4  bg-transparent border border-troo-hrtag rounded w-full placeholder:text-troo-secondary"
                    />
                  </div>
                  <div className="relative w-full">
                    <p className="mb-3">Time</p>                
                    <input
                      type="time"
                     
                      className="p-4  bg-transparent border border-troo-hrtag rounded w-full placeholder:text-troo-secondary"
                    />
                  </div>
                  <div className="w-full">
                    <p className="mb-3">Write Comment</p>
                    <textarea
                      placeholder="White something to me"
                      className="p-5 bg-transparent border focus:outline-none focus-visible:ring-1 rounded w-full"
                      rows={3}
                    />
                  </div>
                  <div className="text-left">
                  <Link
                  to="/about-us"
                  className="btn1 bg-troo-primary text-troo-white hover:bg-troo-secondary
                "
                >
                  Contact Us <HiOutlineChevronRight/>
                </Link>
                  </div>
                </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyDetails;
