import React from 'react'
import Layout from '../../Components/Layout'
import HeroGlobal from '../../Components/HeroGlobal'
import Teams from '../../Components/Teams'
import useDocumentTitle from '../../Hooks/PageTitle'

const TeamPage = () => {
  useDocumentTitle("TRoo Real Estate | Teams Page")
  return (
   <Layout>
    <HeroGlobal/>
    <Teams isTeamPage/>
   </Layout>
  )
}

export default TeamPage