import React, { useEffect, useState } from "react";
import Heading from "../Components/Heading";
import { Link } from "react-router-dom";
import {
  HiOutlineChevronDoubleRight,
  HiOutlineChevronRight,
} from "react-icons/hi";
import { BlogData } from "../Data/BlogData";
import Brands from "./Brands";

const Blogs = ({ isBlog }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (isBlog) {
      setData(BlogData);
    } else {
      setData(BlogData.slice(0, 3));
    }
  }, []);
  return (
    <div className="bg-troo-tertiary py-12 lg:py-24">
      <div className="main_container1">
        {!isBlog && (
          <div className="flex flex-col md:flex-col justify-between gap-8 mb-12">
            <div>
              <Heading
                title="Our News & Blog"
                heading={
                  <>
                    Read every Real Estate <br />
                    news and blogs
                  </>
                }
              />
            </div>
            <div className="flex justify-end items-end">
              <Link className="btn1 bg-troo-secondary text-troo-white hover:bg-troo-primary hover:text-troo-secondary">
                Contact With Us <HiOutlineChevronRight />
              </Link>
            </div>
          </div>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7">
          {data?.map((e, i) => (
            <div>
              <Link
                to={`/our-blogs/${e.slug}`}
                key={i}
                className="flex flex-col gap-5 p-5 bg-troo-white group"
              >
                <div className="flex gap-5">
                  <p className="p-2 bg-troo-secondary text-troo-white rounded group-hover:bg-troo-primary transition-all duration-500 ease-in-out">
                    Real Estate
                  </p>
                  <p className="p-2 bg-troo-secondary text-troo-white rounded group-hover:bg-troo-primary transition-all duration-500 ease-in-out">
                    Living Room
                  </p>
                </div>
                <h3>{e.name}</h3>
                <div className="inline-flex">
                  <p className="border border-troo-black p-1">{e.date}</p>
                </div>
                <div>
                  <img className="w-full" src={e.img} alt={e.name} />
                </div>
                <div>
                  <Link className="btn1 bg-troo-secondary group-hover:bg-troo-primary text-troo-white">
                    Read Full Blog <HiOutlineChevronDoubleRight />
                  </Link>
                </div>
              </Link>
            </div>
          ))}
        </div>
        <div className="lg:mt-24 mt-12">
          <Brands />
        </div>
      </div>
    </div>
  );
};

export default Blogs;
