import React, { useEffect, useState } from "react";
import Heading from "./Heading";
import { PropertyCategoryData } from "../Data/PropertyCategoryData";
import { Link } from "react-router-dom";
import { HiOutlineChevronRight } from "react-icons/hi";
import { motion } from "framer-motion";

const PropertyCategory = ({ isCategogoryPage }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (isCategogoryPage) {
      setData(PropertyCategoryData);
    } else {
      setData(PropertyCategoryData.slice(0, 7));
    }
  }, []);
  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        <div className="mb-12">
          <Heading
            title="Choose Your Categories"
            heading="Our popular categories"
            isCenter
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-7">
          {data.map((e, i) => (
            <motion.div
            initial={{ y: 30, opacity: 0, scale: 0.2 }}
              whileInView={{ y: 0, opacity: 1, scale: 1 }}
              transition={{ type: "spring", stiffness: "100", delay: 0.2 * i }}
            className="group">
              <div key={i} className="bg-troo-tertiary flex flex-col gap-5 p-5">
                <div>
                  <img src={e.img} alt={e.name} />
                </div>
                <h3>{e.name}</h3>
                <p>{e.para}</p>
              </div>
              <Link className="btn1 items-start group-hover:bg-troo-primary bg-troo-secondary text-troo-white w-full ">
                Raed More <HiOutlineChevronRight />
              </Link>
            </motion.div>
          ))}
          {!isCategogoryPage && (
            <div className="flex justify-center items-center">
              <Link
                to="/our-services"
                className=" flex flex-col gap-3 justify-center items-center bg-troo-tertiary rounded-[50%] h-72 w-72 border border-troo-secondary"
              >
                <h2>05+</h2>
                <p className="text-center">
                  The leading real estate marketplace. Search millions of
                  for-sale.
                </p>
                <Link className="btn1 group-hover:bg-troo-secondary ">
                  View All Services
                </Link>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PropertyCategory;
