import React from "react";
import { BrandData } from "../Data/BrandData";
import { motion } from "framer-motion";

const Brands = () => {
  return (
    <div className=" py-12 rounded shadow-lg bg-troo-white">
        <div className="text-center mb-12">
          <p>We are very fortunate to work with these amazing partners</p>
        </div>
        <div className="flex items-center flex-wrap justify-center gap-1">
          {BrandData.map((e, i) => (
            <motion.div
              initial={{ y: 30, opacity: 0, scale: 0.5 }}
              whileInView={{ y: 0, opacity: 1, scale: 1 }}
              transition={{ type: "spring", stiffness: "100", delay: 0.1 * i }}
              className="bg-troo-white px-4 py-4 max-w-[207px]"
              key={i}
            >
              <img className="w-full" src={e.img} alt={e.img} />
            </motion.div>
          ))}
        </div>
    </div>
  );
};

export default Brands;
