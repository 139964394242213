import React, { useEffect, useState } from "react";
import { GrLocation } from "react-icons/gr";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { BsCalendarDay } from "react-icons/bs";
import { AiOutlineHome } from "react-icons/ai";
import { LuBath } from "react-icons/lu";
import { MdOutlineBedroomParent, MdOutlineZoomOutMap } from "react-icons/md";
import { FaRegShareFromSquare } from "react-icons/fa6";
import { GalleryData } from "../Data/GalleryData";
import { useParams } from "react-router-dom";
import { PropertyData } from "../Data/PropertyData";

const PropertyGallery = () => {
  const {slug} = useParams()
  const [propertyData, setPropertyData] = useState()
  useEffect(() => {
    const filteredData = PropertyData.filter((data) => data.slug === slug)
    setPropertyData(filteredData[0])
  }, [slug])
  

  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        <h2 className="mb-5">{ propertyData?.name1 || "Regal North Hills"} </h2>
        <div className="flex flex-col md:flex-row justify-between gap-5">
          <div className="flex flex-col gap-5">
            <div className="flex flex-wrap items-center gap-5">
              <p className="p-2 bg-troo-tertiary rounded flex items-center gap-2">
                <GrLocation /> 4016 Doane Street, Fremont CA 94538
              </p>
              <p className="p-2 bg-troo-tertiary rounded flex items-center gap-2 ">
                <span className="w-3 h-3 bg-troo-primary rounded-full"></span>
                For Sale
              </p>
              <p className="p-2 bg-troo-tertiary rounded flex items-center gap-2">
                <BsCalendarDay />1 Month Ago
              </p>
              <p className="p-2 bg-troo-tertiary rounded flex items-center gap-2">
                <MdOutlineRemoveRedEye />
                250
              </p>
            </div>
            <div className="inline-block">
              <div className="inline-flex bg-troo-tertiary flex-wrap">
                <p className="py-3 px-5 flex gap-3 items-center border-r border-dashed border-troo-black">
                  <AiOutlineHome /> 3 Room
                </p>
                <p className="py-3 px-5 flex gap-3 items-center  border-r border-dashed border-troo-black">
                  <MdOutlineBedroomParent /> 5 Bed
                </p>
                <p className="py-3 px-5 flex gap-3 items-center  border-r border-dashed border-troo-black">
                  <LuBath /> 1 Bath
                </p>
                <p className="py-3 px-5 flex gap-3 items-center ">
                  <MdOutlineZoomOutMap /> 732 sft
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-5 items-end">
            <div className="flex items-center gap-2">
              <h3>$5,520.00</h3>
              <p>/ $2,300/sq ft</p>
            </div>
            <div className="bg-troo-primary p-2 rounded">
              <FaRegShareFromSquare className="text-troo-white" />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mt-12">
          {GalleryData.map((e, i) => (
            <div className={`${(i === 2 || i === 3) && "col-span-2"}`}>
              <img className="h-full object-cover w-full" src={e.img} alt="galleryImgs" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PropertyGallery;
