import React from 'react'
import Layout from '../../Components/Layout'
import HeroGlobal from '../../Components/HeroGlobal'
import PropertyDetails from '../../Components/PropertyDetails'
import PropertyGallery from '../../Components/PropertyGallery'
import SimilarProperty from '../../Components/SimilarProperty'
import useDocumentTitle from '../../Hooks/PageTitle'

const PropertyDetailsPage = () => {
  useDocumentTitle("TRoo Real Estate | Property Details")
  return (
   <Layout>
    <HeroGlobal/>
    <PropertyGallery/>
    <PropertyDetails/>
    <SimilarProperty/>
   </Layout>
  )
}

export default PropertyDetailsPage