import category1 from "../images/categoty1.svg";
import category2 from "../images/categoty2.svg";
import category3 from "../images/categoty3.svg";
import category4 from "../images/categoty4.svg";
import category5 from "../images/categoty5.svg";
import category6 from "../images/categoty6.svg";
import category7 from "../images/categoty7.svg";
import category8 from "../images/categoty8.svg";
import category9 from "../images/categoty9.svg";

export const PropertyCategoryData = [
  {
    id: 1,
    img: category1,
    name: "Apartments",
    para : "The leading real estate marketplace. Search millions of for-sale and rental   listings, compare"
  },
  {
    id: 2,
    img: category2,
    name: "House",
    para : "The leading real estate marketplace. Search millions of for-sale and rental   listings, compare"
  },
  {
    id: 3,
    img: category3,
    name: "Family",
    para : "The leading real estate marketplace. Search millions of for-sale and rental   listings, compare"
  },
  {
    id: 4,
    img: category4,
    name: "Residential",
    para : "The leading real estate marketplace. Search millions of for-sale and rental   listings, compare"
  },
  {
    id: 5,
    img: category5,
    name: "Industrial",
    para : "The leading real estate marketplace. Search millions of for-sale and rental   listings, compare"
  },
  {
    id: 6,
    img: category6,
    name: "Commercial",
    para : "The leading real estate marketplace. Search millions of for-sale and rental   listings, compare"
  },
  {
    id: 7,
    img: category7,
    name: "Village",
    para : "The leading real estate marketplace. Search millions of for-sale and rental   listings, compare"
  },
  {
    id: 8,
    img: category8,
    name: "Office",
    para : "The leading real estate marketplace. Search millions of for-sale and rental   listings, compare"
  },
  {
    id: 9,
    img: category9,
    name: "Row House",
    para : "The leading real estate marketplace. Search millions of for-sale and rental   listings, compare"
  },
  {
    id: 10,
    img: category1,
    name: "Tower",
    para : "The leading real estate marketplace. Search millions of for-sale and rental   listings, compare"
  },
  {
    id: 11,
    img: category5,
    name: "Stadium",
    para : "The leading real estate marketplace. Search millions of for-sale and rental   listings, compare"
  },
  {
    id: 12,
    img: category2,
    name: "Mobile Home",
    para : "The leading real estate marketplace. Search millions of for-sale and rental   listings, compare"
  },
];
