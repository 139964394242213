import { Link } from "react-router-dom";
import Counter from "./Counter";
import { HiOutlineChatBubbleLeftRight } from "react-icons/hi2";
import { motion } from "framer-motion";
import { HiOutlineChevronRight } from "react-icons/hi";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";


const HeroSection = () => {

  return (
    <div className=" top-0 w-full">
      <div className="bg-hero-img bg-no-repeat bg-cover w-full  relative  before:content-[''] before:w-full before:h-full before:absolute before:bg-troo-secondary before:opacity-70 before:top-0 before:left-0 before:z-[-1] z-[1]">

        <div className="hidden xl:block absolute lg:-rotate-90 -left-24 lg:w-auto lg:top-1/2 bg-transparent">
          <div className="flex p-6 items-center justify-center gap-5 ">
            <p className="relative before:absolute before:content-[''] before:w-44 before:h-[2px] before:top-2 before:-left-48 before:bg-troo-white text-troo-white">
              We Are on:
            </p>
            <motion.div
            initial={{ x: 50, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              type: "spring",
              stiffness: "100",
              delay: 0.5 * 1,
            }}
            className="w-10 h-10 flex justify-center items-center bg-troo-white hover:bg-troo-primary transition-all ease-in-out duration-500 rounded-full text-troo-black rotate-90">
              <FaFacebookF />
            </motion.div>
            <motion.div
             initial={{ x: 50, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
             transition={{
               type: "spring",
               stiffness: "100",
               delay: 0.5 * 2,
             }}
            className="w-10 h-10 flex justify-center items-center bg-troo-white hover:bg-troo-primary transition-all ease-in-out duration-500 rounded-full text-troo-black rotate-90">
              <FaInstagram />
            </motion.div>
            <motion.div
             initial={{ x: 50, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
             transition={{
               type: "spring",
               stiffness: "100",
               delay: 0.5 * 3,
             }}
            className="w-10 h-10 flex justify-center items-center bg-troo-white hover:bg-troo-primary transition-all ease-in-out duration-500 rounded-full text-troo-black rotate-90">
              <FaLinkedinIn />
            </motion.div>
            <motion.div
             initial={{ x: 50, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
             transition={{
               type: "spring",
               stiffness: "100",
               delay: 0.5 * 4,
             }}
            className="w-10 h-10 flex justify-center items-center bg-troo-white hover:bg-troo-primary transition-all ease-in-out duration-500 rounded-full text-troo-black rotate-90">
              <FaTwitter />
            </motion.div>
          </div>
        </div>
        <div className="main_container1 pt-56">
          <div className=" flex flex-col gap-10 items-center text-troo-white">
            <motion.h1
             initial={{ y: -100, opacity : 0 }}
             whileInView={{ y: 0, opacity : 1 }}
             transition={{ duration: 2, type: "spring" }}
            className="max-w-[900px] text-center">
              Bring your family’s happiness to your dream house
            </motion.h1>
            <motion.p
            initial={{ y: 100, opacity : 0 }}
            whileInView={{ y: 0, opacity : 1 }}
            transition={{ duration: 2, type: "spring" }}
            className="max-w-[800px] text-center">
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable.
            </motion.p>
            <div className="flex flex-col sm:flex-row gap-10">
              <motion.div  initial={{ x: -50, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{
                  type: "spring",
                  stiffness: "100",
                  delay: 0.5 * 1,
                }}>
                <Link
                  to="/about-us"
                  className="btn1 bg-troo-primary hover:bg-troo-white hover:text-troo-secondary"
                >
                  Explore Property
                </Link>
              </motion.div>
              <motion.div initial={{ x: -50, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{
                  type: "spring",
                  stiffness: "100",
                  delay: 0.5 * 2,
                }}>
                <Link
                  to="/about-us"
                  className="btn1 bg-troo-secondary hover:bg-troo-primary hover:text-troo-secondary"
                >
                  Contact With Us <HiOutlineChevronRight />
                </Link>
              </motion.div>
            </div>
            <Counter />
          </div>
        </div>
        <div className="flex justify-center lg:justify-end mt-16">
          <div className="bg-troo-primary p-5 flex justify-center items-center">
            <div className="w-8 h-8 text-troo-white  text-3xl">
              <HiOutlineChatBubbleLeftRight />
            </div>
          </div>
          <div className="bg-troo-white py-5 px-4 md:px-8">
            <p>Contact With Us</p>
            <h4>+44 123 456 7890, info@trootheme.com</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
