import React from "react";
import { HiOutlineChevronRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import { PropertyData } from "../Data/PropertyData";

const SimilarProperty = () => {
  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        <div className="text-center">
          <h2>Nearby Similar Property</h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-12">
          {PropertyData.slice(0,3).map((e, i) => (
            <div className="group">
              <Link className="bg-troo-white p-2 flex flex-col gap-5" key={i}>
                <div className="relative before:content-[''] before:absolute before:w-full before:h-full before:bg-troo-secondary before:bg-opacity-70 before:scale-x-0 hover:before:scale-100 before:transition-all before:ease-in-out before:duration-500">
                  <img className="w-full" src={e.img} alt={e.name1} />
                  <p className="p-2 bg-troo-white absolute top-5 left-5">
                    {e.sub}
                  </p>
                </div>
                <p>{e.location}</p>
                <h4>
                  {e.name1}-{e.name2}
                </h4>
                <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 ">
                  {e.fetures.map((ele, i) => (
                    <div
                      className={` ${
                        i === e.fetures.length - 1
                          ? ""
                          : "border-r border-dashed border-troo-secondary "
                      } ${i === e.fetures.length && ""}`}
                    >
                      <div>{ele.icon}</div>
                      <p>{ele.fname}</p>
                    </div>
                  ))}
                </div>
              </Link>
              <Link className="p-2 bg-troo-secondary group-hover:bg-troo-primary flex justify-between items-center transition-all duration-500 ease-in-out">
                <p className="text-troo-white pl-5">{e.price}</p>
                <Link className="btn1 bg-troo-primary text-troo-white group-hover:bg-troo-secondary ">
                  Learn More <HiOutlineChevronRight />
                </Link>
              </Link>
            </div>
          ))}
        </div>

      </div>
    </div>
  );
};

export default SimilarProperty;
