import React from 'react'
import Layout from '../../Components/Layout'
import HeroGlobal from '../../Components/HeroGlobal'
import Testimonials from '../../Components/Testimonials'
import useDocumentTitle from '../../Hooks/PageTitle'

const TestimonialPage = () => {
  useDocumentTitle("TRoo Real Estate | Testimonials Page")
  return (
    <Layout>
        <HeroGlobal/>
        <Testimonials/>
    </Layout>
  )
}

export default TestimonialPage