
import { HiOutlineKey } from 'react-icons/hi2';
import { LuBadgeCheck } from 'react-icons/lu';
import { TbCoins } from 'react-icons/tb';


export const HighlightData = [
  {
    id: 1,
    img : <LuBadgeCheck/>,
    name: "Property Managment",
    para: "The leading real estate marketplace. Search millions of for-sale and rental listings, compare",
  },
  {
    id: 2,
    img : <HiOutlineKey/>,
    name: "Property Managment",
    para: "The leading real estate marketplace. Search millions of for-sale and rental listings, compare",
  },
  {
    id: 3,
    img : <TbCoins/>,
    name: "Property Managment",
    para: "The leading real estate marketplace. Search millions of for-sale and rental listings, compare",
  },
];
