import React from "react";
import { HiOutlineChevronRight } from "react-icons/hi";

const Heading = ({ heading, title, textLight, isCenter,isDark }) => {
  return (
    <div
      className={`flex flex-col  gap-6 ${
        textLight ? "text-troo-white" : "text-troo-black"
      } ${isCenter ? "text-center items-center" : "text-left"}`}
    >
      <div>
        <div className={`inline-flex gap-3 items-center rounded-full text-troo-white p-2  ${isDark ? "bg-troo-primary" : "bg-troo-secondary"}`}>
          <HiOutlineChevronRight className={` w-6 h-6 rounded-full p-1 ${isDark?"bg-troo-secondary":"bg-troo-primary"}`} />
          <p>{title}</p>
        </div>
      </div>
      <h2 className="max-w-[750px]">{heading}</h2>
    </div>
  );
};

export default Heading;
