import React, { useEffect, useState } from "react";
import Heading from "./Heading";
import { PropertyData } from "../Data/PropertyData";
import { Link } from "react-router-dom";
import { HiOutlineChevronRight } from "react-icons/hi";
import { motion } from "framer-motion";

const Propertys = ({ isPropertyPage }) => {
  const [filterData, setFilterData] = useState(PropertyData);
  const [activeTab, setActiveTab] = useState("all");

  const handleFilterList = (field) => {
    setActiveTab(field);
    if (field === "all") {
      setFilterData(PropertyData);
    } else {
      setFilterData(PropertyData.filter((data) => data.sub === field));
    }
  };

  useEffect(() => {
    if (isPropertyPage) {
      setFilterData(PropertyData);
    } else {
      setFilterData(PropertyData.slice(0,6));
    }
  }, [isPropertyPage]);
  return (
    <div className="bg-troo-tertiary py-12 lg:py-24">
      <div className="main_container1">
        {!isPropertyPage && (
          <div className="mb-12">
            <Heading
              title="Our Property"
              heading="Explore featured listed property"
              isCenter
            />
          </div>
        )}
        <div className="text-center">
          <div className="lg:mb-16 mb-8  text-center inline-flex flex-col lg:flex-row gap-5 py-5 px-5 rounded-3xl lg:rounded-[100px] ">
            {["all", "For Sell", "For Rent"].map((tab) => (
              <p
                key={tab}
                className={`px-5 py-3 rounded-lg border inline-flex justify-center border-troo-white cursor-pointer  text-troo-white ${
                  activeTab === tab ? "bg-troo-primary" : "bg-troo-secondary"
                }`}
                onClick={() => handleFilterList(tab)}
              >
                {tab === "all" ? "All Property" : tab}
              </p>
            ))}
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {filterData.map((e, i) => (
            <motion.div
            initial={{ y: -30, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1}}
            transition={{ type: "spring", stiffness: "100", delay: 0.2 * i }}
            className="group">
              <Link to={`/property-listing/${e.slug}`} className="bg-troo-white p-2 flex flex-col gap-5" key={i}>
                <div className="relative before:content-[''] before:absolute before:w-full before:h-full before:bg-troo-secondary before:bg-opacity-70 before:scale-x-0 hover:before:scale-100 before:transition-all before:ease-in-out before:duration-500">
                  <img className="w-full" src={e.img} alt={e.name1} />
                  <p className="p-2 bg-troo-white absolute top-5 left-5">
                    {e.sub}
                  </p>
                </div>
                <p>{e.location}</p>
                <h4>
                  {e.name1}-{e.name2}
                </h4>
                <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 ">
                  {e.fetures.map((ele, i) => (
                    <div
                      className={` ${
                        i === e.fetures.length - 1
                          ? ""
                          : "border-r border-dashed border-troo-secondary "
                      } ${i === e.fetures.length && ""}`}
                    >
                      <div>{ele.icon}</div>
                      <p>{ele.fname}</p>
                    </div>
                  ))}
                </div>
              </Link>
              <Link className="p-2 bg-troo-secondary group-hover:bg-troo-primary flex justify-between items-center transition-all duration-500 ease-in-out">
                <p className="text-troo-white pl-5">{e.price}</p>
                <Link className="btn1 bg-troo-primary text-troo-white group-hover:bg-troo-secondary ">
                  Learn More <HiOutlineChevronRight />
                </Link>
              </Link>
            </motion.div>
          ))}
        </div>
        {!isPropertyPage && (
          <div className="text-center mt-12">
            <Link className="btn1 bg-troo-primary text-troo-white hover:bg-troo-secondary ">
              View All Property <HiOutlineChevronRight />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Propertys;
