import React from "react";
import logo2 from "../images/Logo2.svg";
import arrow from "../images/arrowupRightcircle.svg";
import { BiPhone, BiTime } from "react-icons/bi";
import { TbMailOpened } from "react-icons/tb";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
const Footer = () => {
  const siteLink = "https://www.troothemes.com/"
  return (
    <div className="bg-footer-Bg bg-no-repeat w-full h-full pt-12 lg:pt-24 bg-troo-secondary">
      <div className="main_container1">
        <div className="flex flex-col lg:flex-row justify-between gap-10 pb-12 border-b border-troo-primary border-opacity-20">
          <div>
            <img src={logo2} alt="logo2" />
          </div>
          <p className="max-w-[600px] text-troo-white">
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable.
          </p>
          <div>
            <img src={arrow} alt="arrowupright" />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 py-8 text-troo-white">
          <div className="flex flex-col gap-4">
            <p>Call Us</p>
            <h4 className="flex items-center gap-3">
              <BiPhone />
              +44 123 456 7890
            </h4>
            <h4 className="flex items-center gap-3">
              <BiPhone />
              +44 123 456 7890
            </h4>
          </div>
          <div className="flex flex-col gap-4">
            <p>Email Us</p>
            <h4 className="flex items-center gap-3">
              <TbMailOpened />
              contact@troothemes.com
            </h4>
            <h4 className="flex items-center gap-3">
              <TbMailOpened />
              info@troothemes.com
            </h4>
          </div>
          <div className="flex flex-col gap-4">
            <p>Location</p>
            <h4 className="flex  gap-3">
              <div className="items-start">
                <HiOutlineLocationMarker />
              </div>
              4516 School Street Danbury, CT, Canada
            </h4>
          </div>
          <div className="flex flex-col gap-4">
            <p>Opening Time</p>
            <h4 className="flex gap-3">
              <div>
                {" "}
                <BiTime />
              </div>
              Mon - Sat: 10 AM to 06 PM Sunday: 11 AM to 02 PM
            </h4>
          </div>
        </div>
        <div className="bg-troo-white bg-opacity-5 flex lg:flex-row flex-col justify-between items-center gap-5 py-2 text-troo-white px-5">
          <p>Copyright © 2023. <Link to={siteLink} className="font-bold">troothemes</Link>. All rights reserved.</p>
          <div className="flex flex-col md:flex-row gap-5">
            <Link>Terms & Conditions</Link>
            <Link>Cookie Policy</Link>
            <Link>Privacy Policy</Link>
          </div>
          <div className="flex gap-3">
            <div className="w-10 h-10 flex justify-center items-center bg-troo-secondary hover:bg-troo-primary transition-all ease-in-out duration-500 rounded-full text-troo-white">
              <FaFacebookF />
            </div>
            <div className="w-10 h-10 flex justify-center items-center bg-troo-secondary hover:bg-troo-primary transition-all ease-in-out duration-500 rounded-full text-troo-white">
              <FaInstagram />
            </div>
            <div className="w-10 h-10 flex justify-center items-center bg-troo-secondary hover:bg-troo-primary transition-all ease-in-out duration-500 rounded-full text-troo-white">
              <FaLinkedinIn />
            </div>
            <div className="w-10 h-10 flex justify-center items-center bg-troo-secondary hover:bg-troo-primary transition-all ease-in-out duration-500 rounded-full text-troo-white">
              <FaTwitter />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
