import gallery1 from "../images/pDetails1.png"
import gallery2 from "../images/pDetails2.png"
import gallery3 from "../images/pDetails3.png"
import gallery4 from "../images/pDetails4.png"
import gallery5 from "../images/pDetails5.png"
import gallery6 from "../images/pDetails6.png"

export const GalleryData = [
    {
        id : 1,
        img : gallery1
    },
    {
        id : 2,
        img : gallery2
    },
    {
        id : 3,
        img : gallery3
    },
    {
        id : 4,
        img : gallery4
    },
    {
        id : 5,
        img : gallery5
    },
    {
        id : 6,
        img : gallery6
    },
]