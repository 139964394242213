import React from "react";
import Layout from "../../Components/Layout";
import HeroGlobal from "../../Components/HeroGlobal";
import BlogDetails from "../../Components/BlogDetails";
import useDocumentTitle from "../../Hooks/PageTitle";

const BlogDetailsPage = () => {
  useDocumentTitle("TRoo Real Estate | Blogs Details")
  return (
    <Layout>
      <HeroGlobal />
      <BlogDetails />
    </Layout>
  );
};

export default BlogDetailsPage;
