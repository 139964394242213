import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Routes/Home";
import AboutPage from "./Routes/AboutPage";
import PropertyCategoryPage from "./Routes/Property/PropertyCategoryPage";
import PropertyList from "./Routes/Property/PropertyList";
import PropertyDetailsPage from "./Routes/Property/PropertyDetailsPage";
import TeamPage from "./Routes/Pages/TeamPage";
import TestimonialPage from "./Routes/Pages/TestimonialPage";
import Error404Page from "./Routes/Pages/Error404Page";
import BlogDetailsPage from "./Routes/Blogs/BlogDetailsPage";
import ContactPage from "./Routes/ContactPage";
import BlogPage from "./Routes/Blogs/BlogPage";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutPage />} />
          <Route path="/property-categories" element={<PropertyCategoryPage />}/>
          <Route path="/property-listing" element={<PropertyList />} />
          <Route path="/property-listing/:slug" element={<PropertyDetailsPage />}/>
          <Route path="/our-team" element={<TeamPage />} />
          <Route path="/testimonial" element={<TestimonialPage />} />
          <Route path="/error-404" element={<Error404Page />} />
          <Route path="/our-blogs" element={<BlogPage />} />
          <Route path="/our-blogs/:slug" element={<BlogDetailsPage />}/>
          <Route path="/contact-us" element={<ContactPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
