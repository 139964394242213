import React from "react";
import Heading from "./Heading";
import about1 from "../images/about1.png"
import about2 from "../images/about2.png"
import { BsArrowRightCircle } from "react-icons/bs";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { HiOutlineChevronRight } from "react-icons/hi";


const Abouts = () => {
  return (
    <div className="bg-about-Bg bg-no-repeat w-full bg-troo-tertiary py-12 lg:py-24">
      <div className="main_container1">
        <div className="grid grid-cols-1 lg:grid-cols-2  gap-8">
          <div className="grid grid-cols-2 gap-5 w-full">
            <img className="w-full" src={about1} alt="about1" />
            <img className="w-full" src={about2} alt="about2" />
          </div>
          <div className="flex w-full flex-col gap-7">
            <div>
              <Heading
                title="Who We Are"
                heading="To be the most trusted name in real estate globally"
              />
            </div>
            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words</p>
            <div className="flex flex-col gap-5 py-5">
              <motion.p
                initial={{ x: -50, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{
                  type: "spring",
                  stiffness: "100",
                  delay: 0.5 * 1,
                }}
                className="flex items-center gap-3"
              >
                <BsArrowRightCircle /> Delivering the most memorable weddings,
                in the least amount of time
              </motion.p>
              <motion.p
               initial={{ x: -50, opacity: 0 }}
               whileInView={{ x: 0, opacity: 1 }}
               transition={{
                 type: "spring",
                 stiffness: "100",
                 delay: 0.5 * 2,
               }}
              className="flex items-center gap-3">
                <BsArrowRightCircle /> Because happy couples deserve beautiful
                wedding
              </motion.p>
              <motion.p 
              initial={{ x: -50, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{
                type: "spring",
                stiffness: "100",
                delay: 0.5 * 3,
              }}
              className="flex items-center gap-3">
                <BsArrowRightCircle /> A wedding that is True as you are!
              </motion.p>
              <motion.p
              initial={{ x: -50, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{
                type: "spring",
                stiffness: "100",
                delay: 0.5 * 4,
              }}
              className="flex items-center gap-3">
                <BsArrowRightCircle />
                Professional, personal, and affordable wedding planner
              </motion.p>
            </div>
            <div>
                <Link
                  to="/about-us"
                  className="btn1 bg-troo-secondary text-troo-white hover:bg-troo-primary hover:text-troo-secondary"
                >
                  Contact With Us <HiOutlineChevronRight />
                </Link>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Abouts;
