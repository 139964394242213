import React from 'react'
import Layout from '../../Components/Layout'
import HeroGlobal from '../../Components/HeroGlobal'
import Propertys from '../../Components/Propertys'
import useDocumentTitle from '../../Hooks/PageTitle'

const PropertyList = () => {
  useDocumentTitle("TRoo Real Estate | Property Lists")
  return (
    <Layout>
        <HeroGlobal/>
        <Propertys isPropertyPage/>
    </Layout>
  )
}

export default PropertyList