import React, { useEffect, useState } from "react";
import Heading from "./Heading";
import { TeamData } from "../Data/TeamData";
import { Link } from "react-router-dom";
import { BiPhoneCall } from "react-icons/bi";
import {
  HiOutlineChevronRight,
  HiOutlineMailOpen,
  HiOutlineShare,
} from "react-icons/hi";
import { motion } from "framer-motion";

const Teams = ({ isTeamPage }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (isTeamPage) {
      setData(TeamData);
    } else {
      setData(TeamData.slice(0, 3));
    }
  }, []);
  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        <div className="mb-12">
          <Heading title="Meet Our Agents" heading="Meet the agents" isCenter />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7">
          {data.map((e, i) => (
            <motion.div
            initial={{ y: 30, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1}}
            transition={{ type: "spring", stiffness: "100", delay: 0.2 * i }}
            key={i}>
              <Link
                to={e.path}
                className="bg-troo-tertiary p-2 flex flex-col gap-6"
              >
                <div className="relative">
                  <img className="w-full" src={e.img} alt={e.name} />
                  <div className="group">
                    <motion.div
                    initial={{ y: 4, opacity: 0}}
                    whileInView={{ y: 0, opacity: 1,}}
                    transition={{ type: "spring", stiffness: "100"}}
                    className="absolute right-5 bg-troo-secondary bottom-5 p-1  rounded-full hidden group-hover:flex  flex-col gap-2">
                      {e.social.map((ele, j) => (
                        <motion.span 
                          initial={{ y: 4, opacity: 0}}
                          whileInView={{ y: 0, opacity: 1,}}
                          transition={{ type: "spring", stiffness: "100", delay: 0.2 * j}}>
                          <Link
                            to={ele.path}
                            key={j}
                            className="flex justify-center items-center w-7 h-7 p-1 bg-troo-white hover:bg-troo-primary transition-all duration-300 ease-in-out rounded-full"
                          >
                            {ele.icon}
                          </Link>
                        </motion.span>
                      ))}
                    </motion.div>
                    <div className="absolute right-5 bg-troo-secondary bottom-5 p-1  rounded-full flex group-hover:hidden flex-col gap-2">
                      <Link className="flex justify-center items-center w-7 h-7 p-1 bg-troo-white hover-bg-troo-primary transition-all duration-300 ease-in-out rounded-full">
                        <HiOutlineShare />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between items-center gap-5">
                  <div className="flex flex-col gap-3">
                    <h4>{e.name}</h4>
                    <p>{e.sub}</p>
                  </div>
                  <div className="flex gap-5">
                    <div className="p-1 w-8 h-8 bg-troo-secondary hover:bg-troo-primary transition-all duration-500 ease-in-out flex justify-center items-center text-troo-white rounded-full">
                      <BiPhoneCall />
                    </div>
                    <div className="p-1 w-8 h-8 bg-troo-secondary hover:bg-troo-primary transition-all duration-500 ease-in-out flex justify-center items-center text-troo-white rounded-full">
                      <HiOutlineMailOpen />
                    </div>
                  </div>
                </div>
              </Link>
            </motion.div>
          ))}
        </div>
        <div className="flex flex-col sm:flex-row justify-center gap-5 mt-12">
          <Link className="btn1 bg-troo-primary text-troo-white hover:bg-troo-secondary">
            Become A Agent?
          </Link>
          <Link
            to="/about-us"
            className="btn1 bg-troo-secondary text-troo-white hover:bg-troo-primary hover:text-troo-secondary"
          >
            Contact With Us <HiOutlineChevronRight />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Teams;
