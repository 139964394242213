import React from "react";
import contactImg from "../images/contactImg.png";
import Input from "./Input";
import { BsChatDots, BsPerson, BsTelephone } from "react-icons/bs";
import { FiEdit, FiMail } from "react-icons/fi";
import Address from "./Address";

const Contact2 = () => {
  return (
    <>
    <div className="pt-12 lg:pt-24">
      <div className="main_container1">
        <div className="flex flex-col lg:flex-row ">
          <div className="lg:pr-8">
            <img className="w-full" src={contactImg} alt="contactImg" />
          </div>
          <div className="flex flex-col gap-5 p-5 lg:p-12 shadow-xl">
            <h3>We’d Love To Hear From You.</h3>
            <p>Choose your preferred day</p>
            <form className="w-full  flex flex-col items-start gap-14">
              <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-6">
                <Input placeholder="Full Name" icon={<BsPerson />} />
                <Input placeholder="Email Adress" icon={<FiMail />} />
                <Input placeholder="Phone No." icon={<BsTelephone />} />
                <Input placeholder="Company Name" icon={<FiEdit />} />
                <div className="flex md:col-span-2 items-start w-full rounded px-5 py-3 justify-between border border-troo-whitefade2">
                  <textarea
                    rows={5}
                    placeholder="Message"
                    className="bg-transparent outline-none w-full"
                  />
                  <div className="text-troo-light text-lg">
                    <BsChatDots />
                  </div>
                </div>
              </div>
              <button className="btn1 bg-troo-primary text-troo-white hover:bg-troo-secondary">
                Send a Message
              </button>
            </form>
          </div>
        </div>
        <div>
            <Address/>
        </div>
      </div>
    </div>
    <div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7343.710423642854!2d72.49508733426413!3d23.029087824997855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9b278e046fa5%3A0xd837def63555f791!2sTRooInbound%20Private%20Limited!5e0!3m2!1sen!2sin!4v1687782488933!5m2!1sen!2sin"
            width="100%"
            height="500"
            style={{ border: "0" }}
            allowfullscreen=""
            loading="lazy"
            title="gmap"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
    </>
  );
};

export default Contact2;
