export const HeaderData = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "about us",
    path: "/about-us",
  },
  {
    title: "property",
    path: "#",
    subNav: [
      {
        subTitle: "property categories",
        path: "/property-categories",
      },
      {
        subTitle: "property listing",
        path: "/property-listing",
      },
      {
        subTitle: "property details",
        path: "/property-listing/regal-north-hills ",
      },
    ],
  },
  {
    title: "Pages",
    path: "#",
    subNav: [

      {
        subTitle: "our team",
        path: "/our-team",
      },

      {
        subTitle: "testimonial",
        heading: "Clients Reviews",
        path: "/testimonial",
      },

      {
        subTitle: "error 404",
        path: "/error-404",
      },
    ],
  },
  {
    title: " Our Blogs",
    path: "#",
    subNav: [
      {
        subTitle: "our blogs",
        path: "/our-blogs",
      },
      {
        subTitle: "blog details",
        path: "/our-blogs/how-to-keep-motivated-as-a-wedding-planner",
      },
    ],
  },
  {
    title: "contact us",
    path: "/contact-us",
  },
  {
    title: "schedule a visit",
    path: "/contact-us",
  },
];
