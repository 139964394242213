import React from "react";
import Heading from "./Heading";
import { FeedbackData } from "../Data/FeedBackData";
import { FaQuoteLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import { HiOutlineChevronRight } from "react-icons/hi";
import { motion } from "framer-motion";

const Testimonials = () => {
  return (
    <div className="bg-feedback-bg bg-no-repeat w-full h-full py-12 lg:py-24 bg-troo-secondary">
      <div className="main_container1">
        <div className="mb-12">
          <Heading
            title="Valuable Clients Reviews"
            heading="What they are say about us"
            isCenter
            textLight
            isDark
          />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {FeedbackData.map((e, i) => (
            <motion.div
            initial={{ y: -30, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1}}
            transition={{ type: "spring", stiffness: "100", delay: 0.2 * i }}
            className="p-5 flex flex-col sm:flex-row gap-5 w-full" key={i}>
              <div className="sm:w-[70%]">
                <img className="w-full" src={e.img} alt={e.name} />
              </div>
              <div className="flex flex-col gap-5 text-troo-white">
                <h4>{e.sub}</h4>
                <p>{e.para}</p>
                <div className="flex flex-col sm:flex-row  justify-between items-center gap-5 ">
                  <div className="flex items-center gap-5">
                    <div>
                      <FaQuoteLeft className="text-troo-primary" />
                    </div>
                    <div>
                      <h4>{e.name}</h4>
                      <p>{e.desg}</p>
                    </div>
                  </div>
                  <div className="flex gap-2 text-troo-primary bg-troo-primary p-2 bg-opacity-10">
                    {e.rating}
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
        <div className="mt-12 text-center">
          <Link
            to="/about-us"
            className="btn1 bg-troo-primary text-troo-white hover:bg-troo-primary hover:text-troo-secondary"
          >
            View All Testimonials <HiOutlineChevronRight />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
