import React from 'react'
import img404 from "../../images/img404.png"
import { Link } from 'react-router-dom'
import useDocumentTitle from '../../Hooks/PageTitle'

const Error404Page = () => {
  useDocumentTitle("TRoo Wedding Planner | Error Page")
  return (

      <div className="bg-error-bg w-full bg-no-repeat bg-cover bg-center pt-12 lg:pt-44 pb-44 lg:py-72">
      <div className="main_container1 flex justify-center">
        <div className="flex flex-col text-center gap-9">
          <div className="">
            <img
              className="lg:max-w-[400px]   mx-auto"
              src={img404}
              alt={img404}
            />
          </div>
          <h2>Sorry! page did not found</h2>
          <h3 className="max-w-[600px] text-center m-auto font-Infont">
          The Page You Are Looking for Might Have Been Removed or Temporarily Unavailable
          </h3>
          <div>
            <Link
              to="/"
              className="btn1 hover:bg-troo-primary bg-troo-secondary text-troo-white "
            >
            GO TO HOMEPAGE
            </Link>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Error404Page