import city1 from "../images/city1.png"
import city2 from "../images/city2.png"
import city3 from "../images/city3.png"
import city4 from "../images/city4.png"
import city5 from "../images/city5.png"
import city6 from "../images/city6.png"
import city7 from "../images/city7.png"
import city8 from "../images/city8.png"

export const CityData = [
  {
    id: 1,
    img : city1,
    name: "Phoenix",
    para: "18+ Prperty",
  },
  {
    id: 2,
    img : city2,
    name: "Los Angeles",
    para: "18+ Prperty",
  },
  {
    id: 3,
    img : city3,
    name: "Chicago",
    para: "18+ Prperty",
  },
  {
    id: 4,
    img : city4,
    name: "Houston",
    para: "18+ Prperty",
  },
  {
    id: 5,
    img : city5,
    name: "San Diego",
    para: "18+ Prperty",
  },

  {
    id: 6,
    img : city6,
    name: "Phoenix",
    para: "18+ Prperty",
  },
  {
    id: 7,
    img : city7,
    name: "Phoenix",
    para: "18+ Prperty",
  },
  {
    id: 8,
    img : city8,
    name: "Philadelphia",
    para: "18+ Prperty",
  },

];
