import React from "react";
import Layout from "../Components/Layout";
import HeroSection from "../Components/HeroSection";
import Abouts from "../Components/Abouts";
import PropertyCategory from "../Components/PropertyCategory";
import Propertys from "../Components/Propertys";
import Highlights from "../Components/Highlights";
import City from "../Components/City";
import Teams from "../Components/Teams";
import Testimonials from "../Components/Testimonials";
import Contact from "../Components/Contact";
import Blogs from "../Components/Blogs";
import useDocumentTitle from "../Hooks/PageTitle";

const Home = () => {
  useDocumentTitle("TRoo Real Estate - React Js Template")
  return (
    <Layout>
      <HeroSection />
      <Abouts />
      <PropertyCategory />
      <Propertys />
      <Highlights />
      <City />
      <Teams />
      <Testimonials />
      <Contact />
      <Blogs/>
    </Layout>
  );
};

export default Home;
