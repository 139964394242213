import React from "react";
import Layout from "../Components/Layout";
import HeroGlobal from "../Components/HeroGlobal";
import Abouts from "../Components/Abouts";
import Highlights from "../Components/Highlights";
import Teams from "../Components/Teams";
import Testimonials from "../Components/Testimonials";
import Contact from "../Components/Contact";
import useDocumentTitle from "../Hooks/PageTitle";

const AboutPage = () => {
  useDocumentTitle("TRoo Real Estate | Abouts Page")
  return (
    <Layout>
      <HeroGlobal />
      <Abouts />
      <Highlights />
      <Teams />
      <Testimonials />
      <Contact />
    </Layout>
  );
};

export default AboutPage;
