import React, { useEffect, useState } from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import { HiOutlineChatBubbleLeftRight } from "react-icons/hi2";
import { Link, useLocation, useParams } from "react-router-dom";
import { PropertyData } from "../Data/PropertyData";
import { BlogData } from "../Data/BlogData";

const HeroGlobal = () => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const location = useLocation();
  const pathName = location.pathname;
  const { slug } = useParams();
  const [serviceDetails, setServiceDetails] = useState();
  const allDataArr = [...PropertyData, ...BlogData];
  useEffect(() => {
    const filteredData = allDataArr.filter((data) => data.slug === slug);
    setServiceDetails(filteredData[0]);
  }, [slug]);
  console.log("serviceDetails", serviceDetails);

  useEffect(() => {
    const pathnameParts = location.pathname.split("/").filter((p) => !!p);
    const breadcrumbs = pathnameParts.map((part, i) => ({
      title: part,
      path: `/${pathnameParts.slice(0, i + 1).join("/")}`,
    }));
    setBreadcrumbs(breadcrumbs);
  }, [location]);
  return (
    <div className=" top-0 w-full">
      <div className="bg-hero-img bg-no-repeat bg-cover w-full  relative  before:content-[''] before:w-full before:h-full before:absolute before:bg-troo-secondary before:opacity-70 before:top-0 before:left-0 before:z-[-1] z-[1]">
        <div className="hidden xl:block absolute lg:-rotate-90 left-0 lg:w-auto lg:top-1/2 bg-transparent">
          <div className="flex p-6 items-center justify-center gap-5 "> 
            <div className="w-10 h-10 flex justify-center items-center bg-troo-white hover:bg-troo-primary transition-all ease-in-out duration-500 rounded-full text-troo-black rotate-90">
              <FaFacebookF />
            </div>
            <div className="w-10 h-10 flex justify-center items-center bg-troo-white hover:bg-troo-primary transition-all ease-in-out duration-500 rounded-full text-troo-black rotate-90">
              <FaInstagram />
            </div>
            <div className="w-10 h-10 flex justify-center items-center bg-troo-white hover:bg-troo-primary transition-all ease-in-out duration-500 rounded-full text-troo-black rotate-90">
              <FaLinkedinIn />
            </div>
            <div className="w-10 h-10 flex justify-center items-center bg-troo-white hover:bg-troo-primary transition-all ease-in-out duration-500 rounded-full text-troo-black rotate-90">
              <FaTwitter />
            </div>
          </div>
        </div>
        <div className="main_container1 pt-56">
          <div className="flex flex-col gap-8 items-center justify-center">
            <h2 className="text-troo-white">Who We Are</h2>
            <ul className="flex flex-wrap gap-1 justify-center text-troo-white">
              <Link to="/">Home</Link>
              <span>/</span>
              {breadcrumbs.map((breadcrumb, index) => (
                <React.Fragment key={index}>
                  <li
                    className={`capitalize text-troo-white ${
                      index === breadcrumbs.length - 1
                        ? "text-troo-secondary font-bold"
                        : ""
                    }`}
                  >
                    <Link
                      to={breadcrumb.path}
                      className={`${
                        breadcrumb.path === pathName
                          ? "text-troo-primary font-bold"
                          : ""
                      } ${breadcrumb.path === "/faq" ? "uppercase" : ""}`}
                    >
                      {breadcrumb.title.replace(/-/g, " ")}
                    </Link>
                  </li>
                  {index !== breadcrumbs.length - 1 && <span>/</span>}
                </React.Fragment>
              ))}
            </ul>
          </div>
        </div>
        <div className="flex justify-center lg:justify-end mt-16">
          <div className="bg-troo-primary p-5 flex justify-center items-center">
            <div className="w-8 h-8 text-troo-white  text-3xl">
              <HiOutlineChatBubbleLeftRight />
            </div>
          </div>
          <div className="bg-troo-white py-5 px-4 md:px-8">
            <p>Contact With Us</p>
            <h4>+44 123 456 7890, info@trootheme.com</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroGlobal;
