import React from "react";
import Heading from "./Heading";
import highlightImg1 from "../images/highlightImg1.png";
import highlightImg2 from "../images/highlightImg2.png";
import high1 from "../images/high1.png";
import high2 from "../images/high2.png";
import high3 from "../images/high3.png";
import { HighlightData } from "../Data/HighlighData";
import { motion } from "framer-motion";

const Highlights = () => {
  return (
    <div className="bg-highlightImg-bg bg-no-repeat w-full h-full py-12 lg:py-24 relative xl:before:absolute before:content-[''] before:w-full before:h-full before:bg-highlight-bg before:bg-no-repeat before:top-0 before:left-0 after:absolute after:content-[''] after:bg-troo-secondary after:bg-opacity-95 after:w-full after:h-full after:top-0 after:left-0  before:z-20 after:z-10">
      <div className="main_container1 relative z-30">
        <div className="flex flex-col lg:flex-row justify-between gap-10">
          <div className="w-full  lg:w-[60%]">
            <div className="flex flex-col md:flex-row gap-6">
              <div className="flex flex-col gap-2 justify-center items-center bg-troo-primary w-full md:w-[40%] py-8 ">
                <div className="flex">
                  <img className="rounded-full" src={high1} alt="high1" />
                  <img className="rounded-full -ml-3" src={high2} alt="high1" />
                  <img className="rounded-full -ml-3" src={high3} alt="high1" />
                </div>
                <h4 className="text-troo-white">150+ Exclusive Agents</h4>
                <p className="text-troo-white">Ready Service You</p>
              </div>
              <div className="p-2 bg-troo-white w-full md:w-[60%] ">
                <img
                  className="w-full"
                  src={highlightImg1}
                  alt="highlightImg1"
                />
              </div>
            </div>
            <div className="mt-6 p-2 bg-troo-white">
              <img className="w-full" src={highlightImg2} alt="highlightImg2" />
            </div>
          </div>
          <div className="w-full lg:w-[40%] flex flex-col gap-8">
            <div>
              <Heading
                title="Why Choose Us"
                heading={
                  <>
                    A few good reasons, <br />
                    Why should you choose us
                  </>
                }
                textLight
                isDark
              />
            </div>
            <div className="flex flex-col gap-6 text-troo-white">
              {HighlightData.map((e, i) => (
                <motion.div
                initial={{ x: -50, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{
                  type: "spring",
                  stiffness: "100",
                  delay: 0.5 * i,
                }}
                className="flex gap-6 justify-between" key={i}>
                  <div>
                    <div className="rounded-full bg-troo-primary bg-opacity-20 text-2xl text-troo-primary w-14 h-14 flex justify-center items-center">{e.img}</div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <h3>{e.name}</h3>
                    <p>{e.para}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Highlights;
