import { FaStar } from 'react-icons/fa';
import client1 from "../images/feedback1.png"
import client2 from "../images/feedback2.png"
import client3 from "../images/feedback3.png"
import client4 from "../images/feedback4.png"

export const FeedbackData = [
    {
        id : 1,
        img : client1,
        name : "Alexa Christian",
        sub : "Awesome Services!!!",
        city : "Los Angeles",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have variations of passages of Lorem Ipsum suffered alteration",
        rating : <><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></>
    },
    {
        id : 2,
        img : client2,
        name : "Bob Muller",
        desg : "Photography",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have variations of passages of Lorem Ipsum suffered alteration",
        rating : <><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></>
    },
    {
        id : 3,
        img : client3,
        name : "Angelo Morar",
        desg : "Catering Services",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have variations of passages of Lorem Ipsum suffered alteration",
        rating : <><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></>
    },
    {
        id : 4,
        img : client4,
        name : "Jennifer Murray",
        desg : "Bridal Mack-Up",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have variations of passages of Lorem Ipsum suffered alteration",
        rating : <><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></>
    },

]