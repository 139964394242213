import React from 'react'
import Layout from '../../Components/Layout'
import HeroGlobal from '../../Components/HeroGlobal'
import PropertyCategory from '../../Components/PropertyCategory'
import useDocumentTitle from '../../Hooks/PageTitle'

const PropertyCategoryPage = () => {
  useDocumentTitle("TRoo Real Estate | Property Category")
  return (
   <Layout>
    <HeroGlobal/>
    <PropertyCategory isCategogoryPage/>
   </Layout>
  )
}

export default PropertyCategoryPage