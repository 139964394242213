import property1 from "../images/property1.png"
import property2 from "../images/property2.png"
import property3 from "../images/property3.png"
import property4 from "../images/property4.png"
import property5 from "../images/property5.png"
import property6 from "../images/property6.png"
import property7 from "../images/property7.png"
import property8 from "../images/property8.png"
import property9 from "../images/property9.png"
import { AiOutlineHome } from 'react-icons/ai';
import { LuBath } from 'react-icons/lu';
import { MdOutlineBedroomParent, MdOutlineZoomOutMap } from 'react-icons/md';

export const PropertyData = [
    {
        id : 1,
        img : property1,
        sub : "For Sell",
        name1 : "Regal North Hills",
        name2 :" 4150 Main at North Hills St, Raleigh",
        slug : "regal-north-hills",
        location: "4016 Doane Street, Fremont CA 94538",
        price : "$5,520",
        fetures : [
           {
            icon : <AiOutlineHome/>,
            fname : "3 Room"
           },
           {
            icon : <MdOutlineBedroomParent/>,
            fname : "5 Bed"
           },
           {
            icon : <LuBath/>,
            fname : "1 Bath"
           },
           {
            icon : <MdOutlineZoomOutMap />,
            fname : "3 Room"
           },
        ]
    },
    {
        id : 2,
        sub : "For Rent",
        img : property2,
        name1 : "Costco Wholesale",
        name2 :" 1021 Oak Forest Ln, Myrtle Beach, SC",
        slug : "costco-wholesal",
        location: "915 Heath Drive, Montgomery AL 36108",
        price : "$5,220",
        fetures : [
           {
            icon : <AiOutlineHome/>,
            fname : "3 Room"
           },
           {
            icon : <MdOutlineBedroomParent/>,
            fname : "5 Bed"
           },
           {
            icon : <LuBath/>,
            fname : "1 Bath"
           },
           {
            icon : <MdOutlineZoomOutMap />,
            fname : "3 Room"
           },
        ]
    },
    {
        id : 3,
        img : property3,
        sub : "For Rent",
        name1 : "Dollar General",
        name2 :" 5416 Rock Quarry Rd, Raleigh, NC 27610",
        slug : "dollar-general",
        location: "2436 Naples Avenue, Panama City FL 32405",
        price : "$3,520",
        fetures : [
           {
            icon : <AiOutlineHome/>,
            fname : "3 Room"
           },
           {
            icon : <MdOutlineBedroomParent/>,
            fname : "5 Bed"
           },
           {
            icon : <LuBath/>,
            fname : "1 Bath"
           },
           {
            icon : <MdOutlineZoomOutMap />,
            fname : "3 Room"
           },
        ]
    },
    {
        id : 4,
        img : property4,
        sub : "For Sell",
        name1 : "Pine Forest Bungalow",
        name2 :" 4150 Main at North Hills St, Raleigh",
        slug : "pine-forest-bungalow",
        location: "126 Sigley Road,Marquette",
        price : "$1,520",
        fetures : [
           {
            icon : <AiOutlineHome/>,
            fname : "3 Room"
           },
           {
            icon : <MdOutlineBedroomParent/>,
            fname : "5 Bed"
           },
           {
            icon : <LuBath/>,
            fname : "1 Bath"
           },
           {
            icon : <MdOutlineZoomOutMap />,
            fname : "3 Room"
           },
        ]
    },
    {
        id : 5,
        img : property5,
        sub : "For Sell",
        name1 : "Best Buy",
        name2 :" 4150 Main at North Hills St, Raleigh",
        slug : "best-buy",
        location: "5331 Rexford Court, Montgomery AL 36116",
        price : "$3,520",
        fetures : [
           {
            icon : <AiOutlineHome/>,
            fname : "3 Room"
           },
           {
            icon : <MdOutlineBedroomParent/>,
            fname : "5 Bed"
           },
           {
            icon : <LuBath/>,
            fname : "1 Bath"
           },
           {
            icon : <MdOutlineZoomOutMap />,
            fname : "3 Room"
           },
        ]
    },
    {
        id : 6,
        img : property6,
        sub : "For Sell",
        name1 : "RCE Theaters",
        name2 :" 4150 Main at North Hills St, Raleigh",
        slug : "RCE-theaters",
        location: "5331 Rexford Court, Montgomery AL 36116",
        price : "$3,520",
        fetures : [
           {
            icon : <AiOutlineHome/>,
            fname : "3 Room"
           },
           {
            icon : <MdOutlineBedroomParent/>,
            fname : "5 Bed"
           },
           {
            icon : <LuBath/>,
            fname : "1 Bath"
           },
           {
            icon : <MdOutlineZoomOutMap />,
            fname : "3 Room"
           },
        ]
    },
    {
        id : 7,
        img : property7,
        sub : "For Rent",
        name1 : "Awesome Interior Apartment",
        name2 :" 4150 Main at North Hills St, Raleigh",
        slug : "awesome-interior-apartment",
        location: "126 Sigley Road,Marquette",
        price : "$5,520",
        fetures : [
           {
            icon : <AiOutlineHome/>,
            fname : "3 Room"
           },
           {
            icon : <MdOutlineBedroomParent/>,
            fname : "5 Bed"
           },
           {
            icon : <LuBath/>,
            fname : "1 Bath"
           },
           {
            icon : <MdOutlineZoomOutMap />,
            fname : "3 Room"
           },
        ]
    },
    {
        id : 8,
        sub : "For Sell",
        img : property8,
        name1 : "Luxury villa in Rego Park",
        name2 :" 4150 Main at North Hills St, Raleigh",
        slug : "luxury-villa-in-rego-park",
        location: "5331 Rexford Court, Montgomery AL 36116",
        price : "$52,720",
        fetures : [
           {
            icon : <AiOutlineHome/>,
            fname : "3 Room"
           },
           {
            icon : <MdOutlineBedroomParent/>,
            fname : "5 Bed"
           },
           {
            icon : <LuBath/>,
            fname : "1 Bath"
           },
           {
            icon : <MdOutlineZoomOutMap />,
            fname : "3 Room"
           },
        ]
    },
    {
        id : 9,
        img : property9,
        sub : "For Rent",
        name1 : "Triple Story House for Rent",
        name2 :" 4150 Main at North Hills St, Raleigh",
        slug : "triple-story-house-for-rent",
        location: "1693 Alice Court, Annapolis MD 21401",
        price : "$52,720",
        fetures : [
           {
            icon : <AiOutlineHome/>,
            fname : "3 Room"
           },
           {
            icon : <MdOutlineBedroomParent/>,
            fname : "5 Bed"
           },
           {
            icon : <LuBath/>,
            fname : "1 Bath"
           },
           {
            icon : <MdOutlineZoomOutMap />,
            fname : "3 Room"
           },
        ]
    },
]