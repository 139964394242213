import React from 'react'
import Layout from '../Components/Layout'
import HeroGlobal from '../Components/HeroGlobal'
import Contact2 from '../Components/Contact2'
import useDocumentTitle from '../Hooks/PageTitle'

const ContactPage = () => {
  useDocumentTitle("TRoo Real Estate | Contact Page")
  return (
    <Layout>
        <HeroGlobal/>
        <Contact2/>
    </Layout>
  )
}

export default ContactPage