import blog1 from "../images/blog1.png"
import blog2 from "../images/blog2.png"
import blog3 from "../images/blog3.png"

export const BlogData = [
    {
        id : 1,
        img : blog1,
        slug : "the-best-technique-to-follow-up-with-real-estate-leade",
        name : "The Best Techniques to Follow Up with Real Estate Leads",
        date : "Date: 25 June, 2023"
    },
    {
        id : 2,
        img : blog2,
        name : "Real Estate and Housing Market Predictions for 2023",
        slug : "real-estate-and-housing-market-prediction-for-2023",
        date : "Date: 15 June, 2023"
    },
    {
        id : 3,
        img : blog3,
        name : "How to Start a Real Estate Business The Beginner’s Guide",
        slug : "how-to-start-a-real-estate-business-the-begineers-guide",
        date : "Date: 05 May, 2023"
    },
    {
        id : 4,
        img : blog3,
        name : "Search For House Rent in a Property For Rent end.",
        slug : "search-for-house-rent-in-a-property-for-rent-end",
        date : "Date: 25 Aug, 2022"
    },
    {
        id : 5,
        img : blog1,
        name : "Woods recommended for double- door fitted wardrobes",
        slug : "woods-recommended-for-double-door-fitted-wardrobes",
        date : "Date: 25 June, 2023"
    },
    {
        id : 6,
        img : blog2,
        name : "The advantages of having big windows, doors for house & office",
        slug : "the-advantage-of-having-big-windows-door-for-house-&-office",
        date : "Date: 25 June, 2023"
    },
]