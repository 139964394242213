import React, { useEffect, useState } from "react";
import blogImg from "../images/blogImg.png";
import { BsCalendarDay, BsChatDots, BsPerson, BsTag, BsTelephone } from "react-icons/bs";
import blogDetailsImg from "../images/BlogDetailsImg.png";
import blogDetailsImg2 from "../images/blogDetailsImg2.png";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import icon1 from "../images/icon1.svg";
import icon2 from "../images/icon2.svg";
import Input from "./Input";
import { FiEdit, FiMail } from "react-icons/fi";
import { useParams } from "react-router-dom";
import { BlogData } from "../Data/BlogData";

const BlogDetails = () => {
  const {slug} = useParams()
  const [blogData, setBlogData] = useState()
  useEffect(() => {
    const filteredData = BlogData.filter((data) => data.slug === slug)
    setBlogData(filteredData[0])
  }, [slug])
  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        <div className="flex flex-col gap-5">
          <h2>{blogData?.name ||"The Best Techniques to Follow Up with Real Estate Leads"}</h2>
          <div className="flex flex-wrap">
            <div className="flex gap-5 items-center pr-8 border-r">
              <div>
                <img className="rounded-full" src={blogImg} alt="blogImg" />
              </div>
              <div>
                <p>
                  <span className="font-bold">Posted by:</span> David Ross
                </p>
              </div>
            </div>
            <div className="flex items-center px-8 border-r">
              <p className="flex">
                <span className="flex items-center gap-3 font-extrabold">
                  <BsCalendarDay /> Date:
                </span>
                20 June, 2023
              </p>
            </div>
            <div className="flex items-center px-8">
              <p className="flex">
                <span className="flex items-center gap-3 font-extrabold">
                  <BsTag /> Tags:
                </span>
                Home Improvement, Real Estate
              </p>
            </div>
          </div>
          <img className="w-full max-h-[530px]" src={blogData?.img || blogDetailsImg} alt="BlogDetailsImg" />
          <div className="flex flex-col self-center mt-8 gap-5 w-full lg:w-3/4 ">
            <h3>Overview</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit
              amet, consectetur notted adipisicing elit sed do eiusmod tempor
              incididunt ut labore et simply free Lorem ipsum dolor sit amet,
              consectetur notted adipisicing elit sed do eiusmod tempor
              incididunt ut labore et simply free text dolore magna aliqua lonm
              andhn.text dolore magna aliqua lonm andhn.notted adipisicing elit
              sed do eiusmod tempor incididunt ut labore et simply free text
              dolore magna aliqua lonm andhn.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit
              amet, consectetur notted adipisicing elit sed do eiusmod tempor
              incididunt ut labore simply free Lorem ipsum dolor sit amet,
              consectetur notted adipisicing elit sed do eiusmod tempor
              incididunt ut labore et simpl.
            </p>
            <h3>Leave some toys out of reach</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit
              amet, consectetur notted adipisicing elit sed do eiusmod tempor
              incididunt ut labore simply free Lorem ipsum dolor.
            </p>
            <div className="p-5 border-t border-troo-primary bg-troo-tertiary">
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable. If you are going to use a passage of Lorem
                Ipsum, you need to be sure there isn't anything.
              </p>
            </div>
            <h3>Choose toys wisely</h3>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-16">
              <img
                className="w-full"
                src={blogDetailsImg2}
                alt={blogDetailsImg2}
              />
              <div className="flex flex-col gap-6">
                <div className="bg-troo-whitefade lg:pl-10 px-5 lg:px-0 py-5 pr-5 relative flex flex-col sm:flex-row  gap-5">
                  <div className="inline-flex">
                    <div className="p-3 bg-troo-primary lg:absolute top-[30px] left-[-40px] rounded">
                      <img src={icon1} alt="icon1" />
                    </div>
                  </div>
                  <div>
                    <h4 className="mb-2 ">SEO Consultant</h4>
                    <p>
                      Lorem ipsum dolor sit amet, and consecte tur adipiscing
                      ipsum dolor sit amet elit.
                    </p>
                  </div>
                </div>
                <div className="bg-troo-whitefade lg:pl-10 px-5 lg:px-0 py-5 pr-5 relative flex flex-col sm:flex-row  gap-5">
                  <div className="inline-flex">
                    <div className="p-3 bg-troo-primary  lg:absolute top-[30px] left-[-40px] rounded">
                      <img src={icon2} alt="icon2" />
                    </div>
                  </div>

                  <div>
                    <h4 className="mb-2">Creative Solutions</h4>
                    <p>
                      Lorem ipsum dolor sit amet, and consecte tur adipiscing
                      ipsum dolor sit amet elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, which don't look even slightly believable. you need to be
              sure there isn't anything embarrassing hidden in the middle of
              text. All the Lorem Ipsum generators on the Internet tend to
              repeat predefined chunks as necessary.
            </p>
            <div className="bg-troo-tertiary rounded p-4 w-full">
              <div className="flex  items-center gap-3">
                <p>Share This Article:</p>
                <FaFacebookF />
                <FaInstagram />
                <FaLinkedinIn />
                <FaTwitter />
              </div>
            </div>
            <h3 className="mt-7">Comments (0)</h3>
            <h4>Leave A Comment</h4>
            <form className="w-full  flex flex-col items-start gap-14">
              <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-6">
                <Input placeholder="Full Name" icon={<BsPerson />} />
                <Input
                  placeholder="Email Adress"
                  icon={<FiMail />}
                />
                <Input placeholder="Phone No." icon={<BsTelephone />} />
                <Input placeholder="Company Name" icon={<FiEdit/>} />
                <div className="flex md:col-span-2 items-start w-full rounded px-5 py-3 justify-between border border-troo-whitefade2">
                  <textarea
                    rows={5}
                    placeholder="Message"
                    className="bg-transparent outline-none w-full"
                  />
                  <div className="text-troo-light text-lg">
                    <BsChatDots />
                  </div>
                </div>
              </div>
              <button className="btn1 bg-troo-primary text-troo-white hover:bg-troo-secondary">
                Send a Message
              </button>
            </form>
          </div>
        
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
